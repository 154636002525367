/* row1 报警状态 */
.main{
    display: flex;
    flex-direction: row;
    height: 100%;
}

.col1 {width: 15%;}
.col1>div>img {width: 12rem;margin: 0.7rem 0}

.col2 {
    position: relative;
    display:flex;
    width: 70%;
}

.alert{
    position: relative;
}

.icon{
    text-align: center;
}
.icon>img{
    width: 3rem;
    margin:0.5rem 1rem 0 1rem;
}
.icon>.opacity{opacity: 0.2; filter: none;}

.text{
    position: absolute;
    top: 0;
    right: 0;
    color: #bbbbbb;
    font-size: 0.6rem;
}



.col3 {float: right;position: relative;width: 11%;height:100%; color: #0094de;}
.col3>span {position:absolute;bottom: 0; width: 100%; height: 3.5rem;line-height: 3.5rem;text-align: center;background-color: #0a0f7a;font-size: 1.2rem;letter-spacing:1px;font-weight:bold;}
