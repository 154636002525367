
.alert_list{position: absolute;top: 13%;right: 8%;width:35%;height: 87%; overflow: hidden; background-color: #0A0F7A;color: #8fe4f9;min-width: 400px;;}

.main {height: 6rem;}
.row {position: relative; display: flex;flex-direction: row; width: 100%;height: 99%;}
.left {width:15%;}
.left img {width: 60%;margin: 1.2rem}
.right {position: relative;display: flex;flex-direction: column; width:100%;padding: 1rem 1rem 0.5rem 0.6rem;}

.right1{ position: relative;height:60%;display: flex;flex-direction: row;justify-content: space-between}
.right1>.room_name {font-size: 1rem;}
.right1>.alert_type_txt {color: #EE0E72;font-size: 1rem; }
.right1>.alert_type_txt6 {color: #fffc00;font-size: 1rem;}

.right2{ position: relative;height:40% ;display: flex;flex-direction: row;justify-content: space-between;}
.right2>.create_time {font-size: 0.8rem;color: #2E617B; width: 75%;}
.right2>.deal>button {background-color: #EE0E72;font-size: 0.8rem;padding: 0.2rem 0.3rem 0.4rem 0.3rem;color: #CFCFD4;border: 0;border-radius: 5px;}
.right2>.deal6>button {background-color: #fffc00;font-size: 0.8rem;padding: 0.2rem 0.3rem 0.4rem 0.3rem;color: #333333;border: 0;border-radius: 5px;}
.right2>.play {bottom: 0;width: 1.5rem;cursor: pointer}
/*.right2>.more {bottom: 0;width: 1.5rem;cursor: pointer}*/

.main>.bottom {width:90%;height: 1%;margin:0 auto;border-bottom:1px solid #2E617B}
.main>.bottom6 {width:96%;height: 1%;margin:0px auto;border-bottom:2px solid #2E617B}

.alert_main>.top {width: 100%;}
.content{float:left;width:82%;height:5rem}

.dealBtn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0.2rem;
}
.bottomDiv{
    clear: both;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding: 1rem 1rem;
}
.bottomDiv>button {
    background-color: #EE0E72;font-size: 1rem;padding: 0.2rem 0.8rem 0.4rem 0.8rem;color: #CFCFD4;border: 0;border-radius: 5px;
}


.alert_list::-webkit-scrollbar {width : 10px;height: 1px;}
.alert_list::-webkit-scrollbar-thumb {border-radius: 10px;background: #141265;}
.alert_list::-webkit-scrollbar-track {border-radius: 10px;background: #221e6e;}

