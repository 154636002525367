/*div{border: 1px solid;}*/
html,body{
    background-color: #ffffff;
}
.main{
    margin: 0px;
    background-color: #ffffff;
}
.flexRow{
    display: flex;
    flex-direction: row;
    overflow: hidden;
    margin: 20px auto;
}
.result{
    flex: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;

}
.result div{
    margin: 20px;
    font-size: 18px;
}

.breath{
    flex: 1;
    height: 250px;
    margin-right: 20px;
}
.heart{
    flex: 1;
    height: 250px;
}
.variance{
    flex: 1;
    height: 1000px;

}
