.roomMain{ width: 100%;height: 100vh;margin:0;min-height: 500px;overflow: hidden;}
.roomMain>.head {position: relative;  height: 15%;margin-bottom: 2%;}
.roomMain>.head>.topn{position: absolute; width: 35%;left: 32%;bottom: 40%;text-align: center;}

.roomMain>.head>.account{position: absolute; left: 2%;top:20%;padding-left: 1.8%; font-size: 0.9rem;color:#46C4DA;border: 0px solid;}
.roomMain>.head>.qback{position: absolute; right: 1%;top:10%;height: 2%;}
.roomMain>.head>.qback div{float: left;margin: 0 0.5rem;}
.roomMain>.head>.qback img{width: 1.2rem;cursor: pointer;}
.roomMain>.head>.qback>.fly_mid {display: inline-block;height: 1.4rem;width: 1px;margin: 0 10px;vertical-align: middle;background-color: #151962;}

.roomMain>.head>.user{position: absolute; width: 34%;left: 33%;bottom: 0;text-align: center;font-size: 1.2rem; color: #58BFD8;}
.roomMain>.head>.user>span{color:#00BAFF;font-size: 1.4rem;}
.roomMain>.head>.date{position: relative; width: 20%;height: 20%;left: 3%;top: 55%;}
.roomMain>.head>.date>.lipic{position:absolute;bottom: -0.4rem;width:11%; }
.roomMain>.head>.date>.day{position: absolute; bottom: 0.2rem;left:15%;font-size: 1rem; color: #58BFD8;}
.roomMain>.head>.date>.week{position: absolute;bottom: 0.2rem;left:40%;font-size: 1rem; color: #58BFD8;}
.roomMain>.head>.date>.time{position: absolute;bottom: 0rem;left:60%;font-size: 1.4rem; color: #58BFD8;}

.roomMain>.head>.alert_shan{position: absolute;width: 20%;height: 35%;bottom: 0;right:10%; color: #58BFD8;}
.roomMain>.head>.alert_shan>.txt{position:absolute;right: 13%;height: 98%;bottom: 0;; font-size: 1.4rem;color: #16ef08;}
.roomMain>.head>.alert_shan>.image{position:absolute;right: 0; width: 10%;height: 100%;}
/* .roomMain>.head>.alert_shan>.image>img{width: 100%;height: 100%;} */
.roomMain>.head>.voice{position: absolute;cursor: pointer;width: 2rem;height: 2rem;bottom: 1.3rem;right:4%;  color: #58BFD8;}


/* content */
.roomMain>.content {position: relative; height: 80%;overflow: hidden;}
.roomMain>.content>.row1 {width: 90%; height: 12%;margin: 0 auto;vertical-align: middle; }




/* row2 报警列表 */
.roomMain>.content>.row2 {width: 90%; height: 63%;margin: 0 auto 3% auto;}
.roomMain>.content>.row2>.col1 {float:left; width: 65.5%; height: 100%;position:relative;}
.roomMain>.content>.row2>.col2 {float:right;width: 31.5%; height: 100%;background-color: #0A0F7A;color: #8fe4f9;}

/* 呼吸心率图 */
.roomMain>.content>.row3 {position: relative; width: 90%; height: 24%;margin: 0 auto;}




