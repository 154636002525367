/*div{border: 1px solid;}*/
html,body{
    padding: 0;margin: 0;
    background-color: #dddddd;
}
.main{
    margin: 0px;
    background-color: #dddddd;
    border: 1px solid #dddddd;
}
.flexRow{
    display: flex;
    flex-direction: row;
    overflow: hidden;
    margin: 20px auto;
}
.result{
    flex: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.result div{
    margin: 20px;
    font-size: 18px;
}

.breath{
    flex: 1;
    height: 250px;
    margin-right: 20px;
}
.heart{
    flex: 1;
    height: 250px;
}
.hotChart{
    flex: 1;
    height: 300px;
    margin: 30px;
}
.rangeBintest{
    flex: 1;
    height: 900px;
}
