/* row3 */
.main{
    width: 100%;
    height: 7rem;
	overflow-x: hidden;
	overflow-y: hidden;
	white-space: nowrap;
    /*border: 1px solid white;*/
    }

 .pre{
     color: #60acac;
    position: absolute;
    top: 3rem;
    left: -5rem;
    font-size: 4rem;
    cursor: pointer;
    -moz-user-select:none;
    -webkit-user-select:none;
 }
 .next{
    color: #60acac;
    position: absolute;
    right: -5rem;
    font-size: 5rem;
    top: 3rem;
    right: -5rem;
    font-size: 4rem;
    cursor: pointer;
    -moz-user-select:none;
    -webkit-user-select:none;
 }
.bedList{ width: 150%;}
.col {float:left;width: 33.3%; height: 100%;margin: 0 auto;}
.bh {}
.bh_col1{float: left; width: 9%;text-align: center;}
.bh_col2{float: left;width: 5%;color: #01fffd;}
.bh_col3{float: left;width: 80%;border-bottom:1px solid #2E617B;}
.bh_col3_c1{float: left;width: 12.5%;}
.bh_col3_c2{float: left;width: 37%;}
.bh_col3_c3{float: left;width: 12.5%;}
.bh_col3_c4{float: left;width: 37%;}
