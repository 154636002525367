/*div{border: 1px solid;}*/
.main{display: flex;width: 100vw;height: 100vh;min-width: 1200px;}
.left{
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    /*flex: 0 0 280px;*/
    flex: 20;
    height: 100%;
    background: #dedede;
}
.center{
    flex: 80;
    display: flex;
    flex-direction: column;
    height: 100vh;
    /*background-color: rgba(245, 247, 249, 1);*/
    background-color: #bbbbbb;
    overflow: hidden;
}
.right{
    /*flex: 0 0 360px;*/
    flex: 20;
    display: flex;
    flex-direction: column;
    height: 100vh;
    /*background-color: rgba(245, 247, 249, 1);*/
    background-color: #bbbbbb;
    overflow: hidden;

}

.logo{margin: 80px auto 80px 50px;height: 70px;}
.logo img{ height: 100%}

.menu1{display: inline-flex;justify-content: flex-start;background-color: #F5F7F9;width: 100%;height: 5.5vh;margin: 10px auto;}
.menu1Img{ display: inline-flex;align-items: center; width:3vw;margin-left: 4vw}
.menu1Img img{height: 58%}
.menu1Txt{display: inline-flex;align-items: center;margin: 0 0 0 0;font-weight: bold;font-size: 2.5vh;color: #333333;}

.menu2{display: inline-flex;justify-content: flex-start;width: 100%;height: 5.5vh;margin: 10px auto;}
.menu2Img{ display: inline-flex;align-items: center; width:3vw;margin-left: 4vw}
.menu2Img img{height: 58%}
.menu2Txt{display: inline-flex;align-items: center;margin: 0 0 0 0;font-weight: bold;font-size: 2.5vh;color: #D1D8DE;}



.row0{ display: flex; justify-content: flex-end; height: 5%;padding-top: 20px}
.row1{ display: flex; height: 10%;}
.row2{ display: flex; height: 20%;}
.row3{ display: flex; height: 5%;}
.row4{ display: flex; height: 30%;}
.row5{ display: flex; height: 30%;}


.lichuang{
    flex: 1;
    margin: 15px 30px 15px 15px;
    background-color: #ffffff;
    border-radius: 24px;
}
.tidong{
    flex: 1;
    display: inline-flex;
    margin: 15px 30px 15px 30px;
    border-radius: 24px;
}
.tidong1{ flex: 2; font-size: 3vh;padding: 1vh 1vw; color: #FFFFFF; }
.tidong2{ flex: 2; display: inline-flex; align-items: center; }
.tidong2 img{ margin: 0 auto; height: 60%;}
.tidong3{ flex: 2; font-size: 3vh; padding: 1vh; color: #FFFFFF; text-align: right }

.alertTitle{
    padding: 1vh 1vw 0 1vw;
    font-size: 3vh;
    flex: 1;
    color: #ffffff;
}
.alertTime{
    flex: 4;
    position: absolute;
    left: 30%;
    font-size: 6vh;
    color: #ffffff;
}
.alertTime img{
    width: 40%;
    margin-left: 2vw;
}

.flex3{
    display: inline-flex;
    flex: 3;

}
.flex1{
    flex: 1;
}
.cTitle{
    font-size: 38px;
    color: #333333;
    /*font-weight: bold;*/
    margin: 3vh 0 0 30px;
}

.monitorTitle{
    font-size: 32px;
    color: #333333;
    margin: 5px 0 0 30px;
}

.heart{ display: inline-flex; flex-direction: column;flex: 3; background-color: #eaeaea; border-radius: 15px; margin: 10px 30px 20px 30px;}
.heart2{ display: inline-flex; flex-direction: column;flex: 1}
.breath{ display: inline-flex; flex-direction: column;flex: 3; background-color: #eaeaea; border-radius: 15px; margin: 10px 30px 20px 30px;}
.breath2{ display: inline-flex; flex-direction: column;flex: 1;}

.row41{height: 16%;display: flex}
.row42{height: 84%;display: flex;}
.heartTop{display: inline-flex;width: 100%; justify-content: space-between;}
.topLeft{display: inline-flex;align-items: center;margin-left: 20px}
.topRight{display: inline-flex;align-items: center;margin-right: 40px;}
.heartImg{height: 2.5vh}
.heartImg img{height: 100%;}
.heartTxt{height: 2.5vh; font-weight: bold;font-size: 2.5vh;margin-left: 10px;color: #666666 }
.heartChart{display: flex;width: 100%;margin: 10px 30px 20px 30px}
.upImg{height: 2.5vh}
.upImg img{height: 100%;}
.upTxt{height: 2.5vh; font-size: 2.5vh;color: #999999;margin:0 0.1vw}


.rRow1{
    flex: 4;
    display: inline-flex;
    flex-direction: column;
}
.rRow2{
    flex: 3;
    display: inline-flex;
}
.rRow3{
    flex: 3;
}

.rr{
    flex: 1;
    display: inline-flex;
    flex-direction: column;
    margin: 1.2vh 2vh 2.4vh 0;
}
.rr1{
    flex: 2;
    position: relative;
}
.rr1>span{
    position: absolute;
    left: 1.5vw;
    top: 1vh;
    color: #333333;
    font-size: 6vh;
}
.hr{
    flex: 1;
    display: inline-flex;
    flex-direction: column;
    margin: 1.2vh 2vh 2.4vh 0;
}
.hr2{
    flex: 4;
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 5vh;
    color: #999999;
}
.hr2 span{
    font-size: 10vh;
    color: #FF7B3F;
    height: 14vh;
}
.rr2{
    flex: 4;
    display: inline-flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 5vh;
    color: #999999;
}
.rr2 span{
    font-size: 10vh;
    color: #3DE0C0;
    height: 14vh;
}
.rr3{
    flex:3;
    text-align: center;
    font-size: 3vh;
    color: #333333;
}

.top{
    fex:1;
    display: inline-flex;
    justify-content: flex-end;
    /*text-align: right;*/
    padding-right: 20px;
}
.tixing{
    margin-right: 1vw;
    display: flex;
    align-items: center;
    width: 5vh;
    height: 5vh;
    background-color: #ffffff;
    border-radius: 12px;
}
.tixing img{
    width: 50%;
    margin: 0 auto;
}
.useradd{
    /*margin: 1vw;*/
    padding: 0 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 11vw;
    height: 5vh;
    background-color: #ffffff;
    border-radius: 12px;
}
.useradd img{
    height: 50%;
}
.useradd div{
    height: 3vh;
    line-height: 3vh;
}
.user{
    width: 15vw;
    height: 5vh;
    display: inline-flex;
    align-items: center;
    /*border: 1px solid;*/
    /*overflow: hidden;*/
    /*background-color: #dddddd;*/
    border-radius: 30px;
    /*margin: 0 30px;*/
    /*border: 1px solid;*/
}
.user_head{
    width: 4vh;
    /*text-align: left;*/
    /*border: 1px solid red;*/
}
.user_head img{
    width: 100%;
}
.user_name{
    flex: 1;
    display: flex;
    height: 50px;
    width: 4vw;
    font-size: 1vw;
    margin: 1vh 0 0 1vw;
    color: #333333;
}
.online{
    display: flex;
    font-size: 0.6vw;
}
.user_online0{
    width: 0.5vh;
    height: 0.5vh;
    border: 1px solid rgba(255, 255, 255, 1);
    background-color: gray;
    border-radius: 50%;
    margin: 0px 5px 0 5px;
}
.user_online1{
    width: 0.5vh;
    height: 0.5vh;
    border: 1px solid rgba(255, 255, 255, 1);
    background-color: rgba(33, 235, 57, 1);
    border-radius: 50%;
    margin: 0px 5px 0 5px;
}

.user_name img{
    height: 2vh;
}
.date{
    flex: 1;
    margin-left: 40px;
    font-size: 2.5vh;
    color: #ffffff;
}
