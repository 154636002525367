/*div{border: 1px solid;}*/
html,body{
    background-color: #100C2A;
}
.main{
    padding: 3vh 0 0 0;
    background-color: #100C2A;
    border: 1px solid #100C2A;
}
.flexRow{
    display: flex;
    flex-direction: row;
    overflow: hidden;
    margin: 10px auto 30px auto;
}
.result{
    flex: 1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.result div{
    margin: 20px;
    font-size: 18px;
}

.breath{
    flex: 1;
    height: 48vh;
}
.heart{
    flex: 1;
    height: 45vh;
}
.variance{
    flex: 1;
    height: 800px;
}
.variance2{
    flex: 1;
    height: 800px;
}
.hotChart{
    flex: 1;
    height: 300px;
    margin: 30px;
}
.rangeBintest{
    flex: 1;
    height: 900px;
}
