
.meng{
    background-color:#090744;
    opacity: 0.6;
    position: absolute;
    z-index: 1;
    left:0;top:0;
    width: 100vw;
    height: 100vh;
}
.select{
    height: 40px;
    line-height: 40px;
    text-align: center;
}
.select select{
    background-color:#050c93;
    width: 100px;
    color: #24ff00;
    border: 0;
}


.confirm{
    background-color: #050c93;
    position: absolute;
    z-index: 100;
    top:8%;
    right:7%;
    width: 18%;
    padding: 10px 0 20px 0;
}

.lim01{
    position:absolute;
    width: 0.8rem;
    top: 5%;
    right: 2%;
}

.voice{
    margin: 6% 0 2%;
    width: 100%;
    text-align: center;
}
.voice > img{
    width: 8%;
}
.txt{
    text-align: center;
    color: #24ff00;
}
.button{
    margin: 5% 0 2%;
    text-align: center;
}

.btn1{
    border: 0;
    font-size: 1rem;
    width: 8rem;height: 2rem;
    background-color: #042bae;
    color: #0d76cb;
}
.btn2{
    border: 0;
    font-size: 1rem;
    width: 6rem;height: 2rem;
    background-color: #1296db;
    color: #050c9e;
}
