.alert_list {height: 100%;}
/*.alert_list div {border: 1px solid}*/
.alert_list>.no {color: #0CFF00;margin: 3rem 5rem;line-height: 2rem;}

.alert_list>.main {height: 19.5%;}
.alert_list>.main>.row {position: relative;display: flex;flex-direction: row;justify-content: space-between; width: 100%;height: 100%;}
.alert_list>.main>.row>.left {width: 15%}
.alert_list>.main>.row>.left img {width: 60%; margin: 1rem}

.right {position: relative;display: flex;flex-direction: column; width:85%;height: 100%;padding: 1rem 1.2rem 0.8rem 0.5rem}

.right1{position: relative;display: flex;flex-direction: row; justify-content: space-between;width: 100%;height: 65%}
.right1>.room_name {width:64% ; font-size: 0.9rem;}
.right1>.alert_type_txt {color: #EE0E72;font-size: 1rem; }
.right1>.alert_type_txt6 {color: #fffc00;font-size: 1rem;}

.right2{position: relative;display: flex;flex-direction: row;justify-content: space-between; width: 100%;height: 35%}
.right2>.create_time {font-size: 0.8rem;color: #2E617B; width: 70%;}
.right2>.deal {}
.right2>.deal>button {background-color: #EE0E72;color: #CFCFD4;font-size: 0.8rem;border: 0;border-radius: 5px;}
.right2>.deal6 {}
.right2>.deal6>button {background-color: #fffc00;color: #333333;font-size: 0.8rem;border: 0;border-radius: 5px;}
.right2>.play {width: 1.2rem;}
.alert_list>.main>.bottom {width:90%;height: 1%;margin:0 auto;border-bottom:1px solid #2E617B}


.dealBtn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0.2rem;
}
