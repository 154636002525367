.roomlist {
    width: 86%; height: 100%;margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
}
.room {
    box-sizing: border-box;
    flex: 0 0 20%;
    color: #CFCFD4;
    text-align: center;
    margin-bottom: 3rem;
    /*margin:0 auto 4rem auto;*/
    /*border: 1px solid red;*/
}
.normal {width: 52%;margin: 0 auto; position: relative;cursor: pointer; }
.shan {position: absolute;left: 0; top: 0;}
.room_name {margin: 1rem auto;}
