.meng{
    background-color:#090744;
    opacity: 0.6;
    position: absolute;
    z-index: 1;
    left:0;top:0;
    width: 100vw;
    height: 100vh;
}

.main{
    position: absolute;
    z-index: 100;
    left: 10%;top: 15%;
    width: 70%;height: 27rem;
    color: aliceblue;
}

.table{
    color: #00b4ff;
}
.table> tbody>tr>td {
    height: 2rem;
}

.left{
    float: left;
    width: 75%;height: 100%;
    background-color: #1c24c8;
}
.right{
    float: left;
    width: 25%;height: 100%;
    background-color: #7facff;
}

.leftmain{
    margin: 1rem;
}

.tjsj{
    font-size: 1rem;
    font-weight: bold;
    color: #ffc600;
    line-height: 1.8rem;
    margin: 0.5rem;
}
.tjsj>div{
    clear: both;
}
.tjsj>div>.col1{
    width: 30%;
}
.tjsj>div>.col2{
    width: 50%;
}
.tjsj>div>div{
    float: left;
}
.tjsj>div>div>span{
    font-weight: normal;
    font-size: 0.8rem;
}


.event{
    clear: both;
    font-size: 1rem;
    font-weight: bold;
    color: #00fe24;
    height: 1.8rem;
    margin: 0.5rem;
    border-bottom: 1px solid #00b4ff;
}

.zhenduan{
    clear: both;
    font-size: 1rem;
    font-weight: bold;
    color: #00fe24;
    height: 2rem;
    line-height: 2rem;
    margin: 0.5rem;
    border-top: 1px solid #00b4ff;
}
.zhenduan>span{
    font-weight: normal;
    font-size: 0.8rem;
}

.days{
    background-color: #1c24c8;
    color: #00b4ff;
    width: 10rem;
    height: 2rem;line-height: 2rem;
    margin-top: 0.8rem;
    padding-left: 2rem;
    cursor: pointer;
}

.daysCurr{
    background-color: #00fe24;
    color: #1c24c8;
    width: 10rem;
    height: 2rem;line-height: 2rem;
    margin-top: 0.8rem;
    padding-left: 2rem;
}

.cankao{
    margin: 10px;
    font-size: 14px;
    color: #1a99ce;
}
