.login{ width: 100%;height: 100vh;margin:0;min-height: 500px;}
.login_content {position: absolute; width:60%;left:20%;top:15%;}
.login_content_logo {position: relative; width:80%;margin:0 auto;}
.login_content_form {position: relative; width:100%;}
.login_content_form input {height: 100%;line-height: 100%;font-size: 1rem; background-color:transparent;border: none;outline:none;color: #e7e9f2;}
.login_content_form input::-webkit-input-placeholder { color: #abb2d0; }


.login_content_form>.input {position: absolute;width:60%;left: 20%; top: 20%;}
.login_content_form>.input>.input1 {position: absolute;width: 75%;right:0%;top:3%;height: 30%;}
.login_content_form>.input>.input2 {position: absolute;width: 75%;right:0%;top:63%;height:30%;}


.login_content_form>.btn {position: absolute; width: 60%;left: 20%; top: 73%;text-align: right;font-size: 1.4rem;}
.login_content_form>.btn a {margin: 0 2%;text-decoration: none;}
.login_content_form>.btn>.reset{ color: #0968c8;}
.login_content_form>.btn>.submit{ color: #7ac1f8;}
.login_content_form>.btn>.fly_mid {display: inline-block;height: 1.4rem;width: 1px;margin: 0 10px;vertical-align: middle;background-color: #80CBFB;}

.login_content_form>.desc {position: absolute; width: 65%;right: 0; top: 55%;font-size: 0.9rem;color: #0968c8;}
.login_content_form>.err {position: absolute; width: 65%;right: 0;  top: 62%;font-size: 1rem;color: #ffea00;}

