.main{
    position: relative;
    width: 75%;
    margin: 0 auto 30px auto;
    text-align: center;
}

.marquee {
    text-align: center;
    height: 2.2rem;
    line-height: 2.2rem;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;
    width: 90%;
    padding: 0 5rem;
    margin: 0 auto;

}
.span{
    line-height: 2.2rem;
    color: #FF0049;
    margin: 0 0.4rem;
}

.marquee .divpao {
    display: inline-block;
    padding-left: 100%;
    animation: marquee 50s linear infinite;
}
@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}
