.idx{ width: 100%;height: 100vh;margin:0;min-height: 500px;overflow: hidden;}
/* head */
.idx>.head {position: relative;  height: 15%;margin-bottom: 1%; }
.idx>.head>.topn{position: absolute; width: 35%;left: 32%;bottom: 40%;text-align: center;}
.idx>.head>.topn>div{padding:0; color:rgb(89,180,211);font-size: 2rem;letter-spacing: .15rem;}

.idx>.head>.account{position: absolute; left: 2%;top:20%;padding-left: 1.8%; font-size: 0.9rem;color:#46C4DA;border: 0px solid;}
.idx>.head>.qback{position: absolute; right: 1%;top:10%;height: 2%;}
.idx>.head>.qback div{float: left;margin: 0 0.5rem;}
.idx>.head>.qback img{width: 1.2rem;cursor: pointer;}
.idx>.head>.qback>.fly_mid {display: inline-block;height: 1.4rem;width: 1px;margin: 0 10px;vertical-align: middle;background-color: #151962;}
.idx>.head>.user{position: absolute; width: 34%;left: 33%;bottom: 0;text-align: center;font-size: 1.2rem; color: #58BFD8;}

.idx>.head>.date{position: relative; width: 20%;height: 20%;left: 3%;top: 52%;letter-spacing:0.06rem;color: #01FFFD;}
.idx>.head>.date>.lipic{position:absolute;bottom: -0.4rem;left:2%;width:1.9rem; }
.idx>.head>.date>.day{position: absolute; bottom: 0.2rem;left:16%;font-size: 0.9rem; }
.idx>.head>.date>.week{position: absolute;bottom: 0.2rem;left:40%;font-size: 0.9rem;}
.idx>.head>.date>.time{position: absolute;bottom: 0rem;left:58%;font-size: 1.4rem;}

.idx>.head>.alert_list_button{position: absolute;cursor: pointer;width: 6rem;height: 2rem;bottom: 1.2rem;;right:8%; color: #58BFD8;}

.idx>.head>.voice{position: absolute;cursor: pointer;width: 2rem;height: 2rem;bottom: 1.3rem;right:4%;  color: #58BFD8;}


.idx>.content {position: relative; height: 80%;  overflow-y: auto;}
.idx>.content>.roomlist {width: 86%; height: 100%;margin: 0 auto;}
.idx>.content>.roomlist>.room {float: left;cursor: pointer; margin: 2rem auto; width: 19.99%;color: #CFCFD4;text-align: center;}
.idx>.content>.roomlist>.room> .normal {width: 52%;margin: 0 auto; position: relative;}
.idx>.content>.roomlist>.room> .alert {width: 52%;margin: 0 auto; position: relative;}
.idx>.content>.roomlist>.room> .alert>div {position: absolute;left: 0; top: 0;}
.idx>.content>.roomlist>.room>.room_name {margin: 1rem auto;}

.idx>.content::-webkit-scrollbar {width : 5px;height: 1px;}
.idx>.content::-webkit-scrollbar-thumb {border-radius: 10px;background: #1c1a78;}
.idx>.content::-webkit-scrollbar-track {border-radius: 10px;background: rgb(81, 77, 164);}


/*.idx>.alert_list{position: absolute;top: 12%;right: 8%;width:25%;height: 88%; overflow: hidden; background-color: #0A0F7A;color: #8fe4f9;min-width: 400px;;}*/
/*.idx>.alert_list>.main {height: 6rem;}*/
/*.idx>.alert_list>.main>.row {width: 100%;height: 99%; }*/
/*.idx>.alert_list>.main>.row>.left {float:left;width:20%;height:5rem;}*/
/*.idx>.alert_list>.main>.row>.left img {width: 50%; margin: 2rem 1rem 0 3rem}*/
/*.idx>.alert_list>.main>.row>.right {float:left;position: relative; width:75%;height:5.5rem;}*/
/*.idx>.alert_list>.main>.row>.right>.room_name {float: left;font-size: 1rem;margin: 2rem 0 0 1rem;}*/
/*.idx>.alert_list>.main>.row>.right>.alert_type_txt {float:right;color: #EE0E72;font-size: 1rem; margin: 1.5rem 1.5rem 1rem 0;}*/
/*.idx>.alert_list>.main>.row>.right>.alert_type_txt6 {float:right;color: #fffc00;font-size: 1rem; margin: 1.5rem 1.5rem 1rem 0;}*/

/*.idx>.alert_list>.main>.row>.right>.create_time {position: absolute;font-size: 0.8rem; bottom: 0.5rem;left: 1rem;color: #2E617B; width: 70%;}*/
/*.idx>.alert_list>.main>.row>.right>.deal {position: absolute;bottom: 0.3rem;right:20%;}*/
/*.idx>.alert_list>.main>.row>.right>.deal>button {background-color: #EE0E72;font-size: 0.5rem;padding: 0.2rem 0.3rem 0.4rem 0.3rem;color: #CFCFD4;border: 0;border-radius: 5px;}*/
/*.idx>.alert_list>.main>.row>.right>.deal6 {position: absolute;bottom: 0.3rem;right:20%;}*/
/*.idx>.alert_list>.main>.row>.right>.deal6>button {background-color: #fffc00;font-size: 0.5rem;padding: 0.2rem 0.3rem 0.4rem 0.3rem;color: #333333;border: 0;border-radius: 5px;}*/

/*.idx>.alert_list>.main>.row>.right>.play {position: absolute;bottom: 0;right: 8%;width: 1.5rem;}*/


/*.idx>.alert_list>.main>.bottom {width:80%;height: 1%;margin:0 auto;border-bottom:1px solid #2E617B}*/

/*.idx>.alert_list>.alert_main>.top {width: 100%;}*/
/*.idx>.alert_list>.content{float:left;width:82%;height:5rem}*/

/*.idx>.alert_list::-webkit-scrollbar {width : 10px;height: 1px;}*/
/*.idx>.alert_list::-webkit-scrollbar-thumb {border-radius: 10px;background: #141265;}*/
/*.idx>.alert_list::-webkit-scrollbar-track {border-radius: 10px;background: #221e6e;}*/




